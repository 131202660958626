@use '../../styles/yr' as *;

$loading-transition-delay-duration: 100ms;
$loading-transition: $loading-transition-delay-duration ease-in;
$table-heading-cell-padding-vertical: rem(10px);

.weather-location-list {
  // Shift the entire list up the same amount as the top padding of the day cells
  // so that the bottom margin of an element above this list will touch the top of
  // the day cell content. Normally margin collapsing would do this for us
  // automatically if the spacing above the day cells had been defined using margins,
  // but since the day cell container is sticky we've defined the spacing above the
  // day cells using padding instead so we use this negative margin hack to get
  // the same visual result.
  margin-top: $table-heading-cell-padding-vertical;
}

.weather-location-list__heading-row {
  padding-left: size(1);
  display: grid;
  grid-template-areas: '. days days days .';
  // This is set to match the grid of .weather-location-list-item__container
  grid-template-columns: 1fr 1fr 1fr rem(20) rem(50);

  @include mq-gt($mq-990) {
    grid-template-areas: '.  days .';
    // This is set to match the grid of .weather-location-list-item__container
    grid-template-columns: 1fr 45% rem(50);
  }

  border-bottom: $divider-solid;

  @include mq-lte($mq-990) {
    @include sticky-app-top;
    background-color: var(--color-background-base);
    z-index: $z-index-sticky-header;
  }

  @include mq-lte($mq-450) {
    grid-template-areas: '. days days days days';
    grid-template-columns: 1.5fr 1fr 1fr 1fr 1.5fr;
  }
}

.weather-location-list__heading-days {
  grid-area: days;
  display: flex;
  justify-content: space-between;
}

.weather-location-list__heading-day-cell {
  background-color: transparent;
  padding: $table-heading-cell-padding-vertical 0;
  text-align: center;
  min-width: rem(88px);
  transition: color $loading-transition $loading-transition-delay-duration;

  @include mq-lte($mq-450) {
    min-width: rem(76px);
  }

  &:last-child {
    display: none;

    @include mq-gt($mq-990) {
      display: inline-block;
    }
  }
}

.weather-location-list__heading-day-cell--loading {
  color: transparent;

  &:before {
    opacity: 1;
  }
}

.weather-location-list__heading-day-string-short {
  display: none;

  @include mq-lte($mq-450) {
    display: flex;
  }
}

.weather-location-list__heading-day-string-long {
  display: none;

  @include mq-gt($mq-450) {
    display: block;
  }
}
