@use '../../styles/yr' as *;

$gradient-start: map-get($base-gradient-homepage, start);
$gradient-stop: map-get($base-gradient-homepage, stop);

.home-page__background {
  z-index: $z-index-background-image;
  position: absolute;
  top: 0;
  right: 0;
  background-image: url('/assets/images/background-cloud.jpg'), linear-gradient($gradient-start, $gradient-stop);
  background-repeat: no-repeat;
  background-position: top right;
  background-size: auto 40rem;
  height: 40rem;
  width: 100%;

  @include mq-lte($mq-990) {
    background-image: none;
  }
}

[data-theme='dark'] .home-page__background {
  background-image: unset;
  background-repeat: unset;
  background-position: unset;
  background-size: unset;
}

.home-page__background--with-locations {
  @include mq-lte($mq-675) {
    display: none;
  }
}

.home-page__container {
  margin-top: size(2);
  margin-bottom: size(5);

  @include mq-gt($mq-675) {
    margin-bottom: size(8);
  }
}

.home-page__locations {
  .no-js & {
    display: none;
  }
}

.home-page__empty-list {
  background-color: var(--color-background-muted-core-blue);
  border-radius: size(1);
  border: 1px solid var(--color-stroke-subtle-core-blue);
  padding: size(3) size(2);
  color: var(--color-text-accent-core-blue);
}

.home-page__static {
  .js & {
    display: none;
  }
}

.home-page__static-heading {
  margin-bottom: size(3);
  max-width: rem(600px);
}

.home-page__static-search {
  max-width: rem(560px);
}
