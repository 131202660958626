@use '../../styles/yr' as *;

.article-list__article {
  display: block;
  height: 100%;
  position: relative;
}

.article-list__image {
  margin-bottom: size(2);
  width: 100%;
  height: auto;

  border-radius: size(1) size(1) 0 0;

  @include mq-lte($mq-675) {
    margin-bottom: size(1);
  }
}

.article-list__caption {
  @include mq-lte($mq-675) {
    margin-bottom: size(2);
  }
}

.article-list__heading {
  margin-bottom: size(1);
}

.article-list__link {
  text-decoration: none;

  @include hover {
    text-decoration: underline;
  }

  &:after {
    display: block;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

@media screen and (min-width: 675px) {
  .article-list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: size(5);
  }
}
